<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-3市场渗透率分析.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">市场渗透率分析</div>
            <div class="mobile-head-explain-content">全球某化妆品巨头</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">经过二十多年的快速发展，客户公司销售区域遍布全国。当市场规模扩大以后，使用销售额来衡量各区域业
                务经营状况的方式无法满足市场发展需求，需要基于数据分析平台和算法体系的支撑，建立一套适合行业业务特点的全新市场衡量指标。同时，落地运
                营级的销售管理系统，也需要借助成熟的解决方案。渗透率已经成为驱动市场份额增长最重要的因素之一，成功的快消和零售企业都在努力逐个地区的
                提高当地渗透率。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-box1">
                <div class="title1">
                    难以制订合理指标判定地区业务好坏
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    不同地区的市场存在差异，销售队伍难以找到对标，复制成功经验
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    行政区域与销售区域未能精准匹配，线下推广活动具有复杂度
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    较难评估区域业务饱和度与发展潜力值
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    新的管理思路未能形成指标体系与具体操作，需进一步落地，让管理可执行
                </div>
            </div>
            <div class="ware-box1" style="margin-bottom:5rem">
                <div class="title1">
                    数据分析与销售策略未能做到端到端打通，分析结果无法直接指导销售
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <img class="ware-img" src="../../assets/image/selected/03-2-2实施方案.svg"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-2销售管理.svg"/>
                    <div>
                        <div class="title1">销售管理</div>
                        <div class="title2">建立渗透率指标体系，精准判定地区业务好坏</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-2业务评估.svg"/>
                    <div>
                        <div class="title1">业务评估</div>
                        <div class="title2">评估业务发展潜力，成功预测业务回升拐点</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-2区域运营.svg"/>
                    <div>
                        <div class="title1">区域运营</div>
                        <div class="title2">精准划分城市区域，指导实际业务运营，降低企业推广成本</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-2销售预测.svg"/>
                    <div>
                        <div class="title1">销售预测</div>
                        <div class="title2">预测区域业务发展潜力，指导业务发展方向，提高业务销售业绩</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-2业务发展.svg"/>
                    <div>
                        <div class="title1">业务发展</div>
                        <div class="title2">为销售队伍找到对标，复制成功经验，指导经销商业务开发</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-2管理工具.svg"/>
                    <div>
                        <div class="title1">管理工具</div>
                        <div class="title2">建立渗透率管理工具，提高了企业管理者、区域经理以及首席经销商的管理能力</div>
                    </div>
                </div>
            </div>
                
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 12rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 70rem;
    margin-left: -2rem;
    margin-bottom: 5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card{
        width: 52.8rem;
        height: 28rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 6rem;
            height: 6rem;
            margin: 8rem 0 0 5rem;
        }
        .title1{
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 400;
            color: #333333;
            line-height: 4rem;

            margin: 7rem 0 0 1.2rem;
        }
        .title2{
            width: 35rem;
            height: 4.8rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 0rem 0 0 1.2rem;
        }
    }
}
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #612177;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
</style>